const vehicles = [
   {
      "name":"8 Seater Bus",
      "driversLicence":"B",
      "tank":"65ℓ",
      "seats":"8",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"3050KG",
      "tare":"1910KG",
      "payload":"1140KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376095/cargo/8-seater-bus1.jpg"
   },
   {
      "name":"14 Seater Bus",
      "driversLicence":"B+PRDP",
      "tank":"70ℓ",
      "seats":"14",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"3300KG",
      "tare":"2130KG",
      "payload":"1170KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376096/cargo/14-seater-bus1.jpg"
   },
   {
      "name":"1 Ton Refrigerated Truck / Bakkie",
      "driversLicence":"B",
      "tank":"75ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"3000KG",
      "tare":"1710KG",
      "payload":"1290KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376097/cargo/16-ton-refrigerated-truck.jpg"
   },
   {
      "name":"6 Ton Drop Side Truck",
      "driversLicence":"CI+PRDP",
      "tank":"200ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"10500KG",
      "tare":"4680KG",
      "payload":"5820KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376095/cargo/6-ton-drop-side-truck.jpg"
   },
   {
      "name":"8 Ton Drop Side Truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"15500KG",
      "tare":"7000KG",
      "payload":"8500KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376096/cargo/8-ton-drop-side-truck.jpg"
   },
   {
      "name":"14-ton-refrigerated-truck",
      "driversLicence":"B",
      "tank":"75ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"3000KG",
      "tare":"1710KG",
      "payload":"1290KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376097/cargo/14-ton-refrigerated-truck.jpg"
   },
   {
      "name":"8 Ton Curtain Side Truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"15500KG",
      "tare":"7080KG",
      "payload":"8420KG",
      "extra":"Tailift",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376095/cargo/8-ton-curtain-side-truck.jpg"
   },
   {
      "name":"8-ton-crane-hazchem-truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"15500KG",
      "tare":"7000KG",
      "payload":"8500KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376095/cargo/8-ton-crane-hazchem-truck.jpg"
   },
   {
      "name":"14-ton-drop-side-flat-deck-truck",
      "driversLicence":"B",
      "tank":"45ℓ",
      "seats":"5",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"1470KG",
      "tare":"1080KG",
      "payload":"390KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376097/cargo/14-ton-drop-side-flat-deck-truck.jpg"
   },
   {
      "name":"1500 Motor Car",
      "driversLicence":"B",
      "tank":"42ℓ",
      "seats":"5",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"1575KG",
      "tare":"1164KG",
      "payload":"411KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/tank-capacity.svg"
   },
   {
      "name":"½ Ton Bakkie",
      "driversLicence":"B",
      "tank":"52ℓ",
      "seats":"2",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"1940KG",
      "tare":"1096KG",
      "payload":"844KG",
      "extra":"Bin Linning / Canopy",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/passengers.svg"
   },
   {
      "name":"23 Seater Bus",
      "driversLicence":"CI+PRDP",
      "tank":"100ℓ",
      "seats":"22",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"5000KG",
      "tare":"3200KG",
      "payload":"1800KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/power-steering.svg"
   },
   {
      "name":"2.5-ton-refrigerated-truck.jpg",
      "driversLicence":"CI+PRDP",
      "tank":"100ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"5200KG",
      "tare":"3880KG",
      "payload":"1320KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/aircon.svg"
   },
   {
      "name":"Supertonner",
      "driversLicence":"B",
      "tank":"75ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"2730KG",
      "tare":"1522KG",
      "payload":"1208KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/payload.svg"
   },
   {
      "name":"4×4 Single Cab",
      "driversLicence":"B",
      "tank":"80ℓ",
      "seats":"2",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"3000KG",
      "tare":"1830KG",
      "payload":"1170KG",
      "extra":"Canopy",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/cargo-capacity.svg"
   },
   {
      "name":"4×2 Double Cab",
      "driversLicence":"B",
      "tank":"80ℓ",
      "seats":"5",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"2730KG",
      "tare":"1725KG",
      "payload":"1005KG",
      "extra":"Canopy",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/cargo-capacity.svg"
   },
   {
      "name":"4×4 Double Cab",
      "driversLicence":"B",
      "tank":"80ℓ",
      "seats":"5",
      "powerstaring":"Yes",
      "radion":"Yes",
      "aircon":"Yes",
      "gvm":"2805KG",
      "tare":"1962KG",
      "payload":"843KG",
      "extra":"Canopy",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/extras.svg"
   },
   {
      "name":"1 Ton Panel Van",
      "driversLicence":"B",
      "tank":"75ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"3200KG",
      "tare":"1910KG",
      "payload":"1290KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/drivers-license.svg"
   },
   {
      "name":"2½ Ton Panel Van",
      "driversLicence":"CI+PRDP",
      "tank":"100ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"5200KG",
      "tare":"3220KG",
      "payload":"1980KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/tank-capacity.svg"
   },
   {
      "name":"4 Ton Panel Van",
      "driversLicence":"CI+PRDP",
      "tank":"100ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"7500KG",
      "tare":"4000KG",
      "payload":"3500KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/passengers.svg"
   },
   {
      "name":"4 Ton Drop Side Truck",
      "driversLicence":"CI+PRDP",
      "tank":"100ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"7500KG",
      "tare":"3760KG",
      "payload":"3740KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/power-steering.svg"
   },
   {
      "name":"4 Ton Drop Side Truck with Labour Canopy",
      "driversLicence":"CI+PRDP",
      "tank":"100ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"7500KG",
      "tare":"4460KG",
      "payload":"3040KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/radio-cd.svg"
   },
   {
      "name":"4 Ton Refrigerated Truck",
      "driversLicence":"CI+PRDP",
      "tank":"100ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"7500KG",
      "tare":"4500KG",
      "payload":"3000KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/aircon.svg"
   },
   {
      "name":"6 Ton Panel Van",
      "driversLicence":"CI+PRDP",
      "tank":"200ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"10500KG",
      "tare":"5380KG",
      "payload":"5120KG",
      "extra":"Tailift",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/payload.svg"
   },
   {
      "name":"6 Ton Refrigerated Truck",
      "driversLicence":"CI+PRDP",
      "tank":"200ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"11000KG",
      "tare":"5480KG",
      "payload":"5520KG",
      "extra":"Tailift",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/cargo-capacity.svg"
   },
   {
      "name":"8 Ton Panel Van",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"15000KG",
      "tare":"7600KG",
      "payload":"7400KG",
      "extra":"Tailift",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/extras.svg"
   },
   {
      "name":"8 Ton Crane Truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"15000KG",
      "tare":"9200KG",
      "payload":"5800KG",
      "extra":"-",
      "images":"https://superrent.co.za/wp-content/uploads/2019/07/1500-motor-car.jpg"
   },
   {
      "name":"8 Ton Refrigerated Truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"15000KG",
      "tare":"7520KG",
      "payload":"7480KG",
      "extra":"Tailift",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/tank-capacity.svg"
   },
   {
      "name":"8 Ton Hazchem Dropside Truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"15000KG",
      "tare":"7000KG",
      "payload":"8000KG",
      "extra":"-",
      "image":"https://res.cloudinary.com/thiomark/image/upload/v1673376095/cargo/8-ton-crane-hazchem-truck.jpg"
   },
   {
      "name":"14 Ton Drop Side Truck / Flat Deck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"24000KG",
      "tare":"9080KG",
      "payload":"14920KG",
      "extra":"-",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/power-steering.svg"
   },
   {
      "name":"14 Ton Curtain Side Truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"25100KG",
      "tare":"10120KG",
      "payload":"14980KG",
      "extra":"Tailift",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/radio-cd.svg"
   },
   {
      "name":"14 Ton Refrigerated Truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"26000KG",
      "tare":"12940KG",
      "payload":"13060KG",
      "extra":"Tailift",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/aircon.svg"
   },
   {
      "name":"16 Ton Refrigerated Truck",
      "driversLicence":"CI+PRDP",
      "tank":"400ℓ",
      "seats":"3",
      "powerstaring":"Yes",
      "radion":"No",
      "aircon":"No",
      "gvm":"25500KG",
      "tare":"11580KG",
      "payload":"13920KG",
      "extra":"Tailift",
      "images":"https://www.greenhat.co.za/dev/super-rent-2018/staging/wp-content/themes/superrent/images/payload.svg"
   }
]

module.exports = vehicles;