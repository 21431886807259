<template>
    <container-screen>
        <div style="background-color: #DDDDDD;" >
            <div class="flex items-center justify-evenly grid-cols-3 container h-60 px-6 mx-auto">
               
             
           
            </div>
        </div>
        <wrapper-template :container="true" :y="10" :x="6">
            <div class="pb-20 ">
                <span class="text-xs font-medium text-blue-600 uppercase dark:text-blue-400">Over MoparExpressNV</span>
                <h1 class="block mt-2 text-2xl font-semibold text-gray-800 dark:text-white">Over {{$store.state.helpers.siteName && $store.state.helpers.siteName}}</h1>
                <div class="mt-3 space-y-4">
                    <p class="text-sm text-gray-600 dark:text-gray-400">

MoparExpressNV is gevestigd in het hart van een bloeiende handelsstad, waardoor het bedrijf gemakkelijk toegang heeft tot internationale markten en logistieke netwerken. Met een toegewijd team van professionals met uitgebreide ervaring in de import- en exportsector, streeft MoparExpressNV ernaar om aan de diverse behoeften van klanten over de hele wereld te voldoen.
</p>
   <p class="text-sm text-gray-600 dark:text-gray-400">
Het brede scala aan goederen en producten dat MoparExpressNV behandelt, omvat onder andere elektronica, kleding, voedselproducten, auto-onderdelen en industriële apparatuur. Het bedrijf heeft sterke relaties opgebouwd met fabrikanten, leveranciers en distributeurs over de hele wereld, waardoor het in staat is om hoogwaardige producten aan te bieden tegen concurrerende prijzen.
   <p class="text-sm text-gray-600 dark:text-gray-400">
Wat MoparExpressNV onderscheidt van andere bedrijven in de branche, is de nadruk op betrouwbaarheid en kwaliteit. Het bedrijf werkt nauw samen met gerenommeerde partners en voert grondige kwaliteitscontroles uit om ervoor te zorgen dat de goederen die worden geïmporteerd en geëxporteerd aan de hoogste normen voldoen. Klanttevredenheid staat centraal bij MoparExpressNV, en het bedrijf streeft ernaar om elke klant een naadloze en probleemloze ervaring te bieden.
</p>
   <p class="text-sm text-gray-600 dark:text-gray-400">
Het team van MoparExpressNV is zeer bedreven in het beheren van internationale logistiek en douaneformaliteiten. Ze zorgen ervoor dat alle vereiste documenten en vergunningen correct worden afgehandeld, wat resulteert in soepele en efficiënte leveringen over de hele wereld. Het bedrijf maakt gebruik van geavanceerde technologieën en systemen om het transportproces te stroomlijnen en de zendingen nauwkeurig te volgen, zodat klanten altijd op de hoogte zijn van de voortgang van hun goederen.
</p>
   <p class="text-sm text-gray-600 dark:text-gray-400">
MoparExpressNV hecht ook veel waarde aan duurzaamheid en maatschappelijk verantwoord ondernemen. Het bedrijf zet zich in voor milieuvriendelijke praktijken in de logistieke sector en zoekt voortdurend naar manieren om de ecologische voetafdruk te verminderen. Het streeft er ook naar om positieve sociale impact te creëren door middel van betrokkenheid bij lokale gemeenschappen en het ondersteunen van goede doelen.
</p>
   <p class="text-sm text-gray-600 dark:text-gray-400">
Kortom, MoparExpressNV is een jong en dynamisch bedrijf dat excelleert in de import en export van verschillende goederen en producten. Met een sterke reputatie op het gebied van betrouwbaarheid, kwaliteit en klantenservice, is het bedrijf goed gepositioneerd om tegemoet te komen aan de behoeften van klanten wereldwijd en een betrouwbare partner te zijn in de internationale handel.
   </p>                 
                </div>


            <div>


            <iframe width="560" height="315" src="https://www.youtube.com/embed/3MKRQ3gHhkU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            </div>
               
            </div>
        </wrapper-template>
    </container-screen>
</template>

<script>
    import ContainerScreen from '../templates/ContainerScreen.vue'
    import WrapperTemplate from '../templates/WrapperTemplate.vue'
    export default {
        name: 'About',
        components: {
            ContainerScreen,
            WrapperTemplate
        },
        data() {
            return {
                list: [
                    'Drivers and assistants',
                    'Trolleys',
                    'Corner Pieces',
                    'Tarpaulins & cargo Nets',
                    'Straps & Ratchets'
                ],
                images: [
                    'https://images.pexels.com/photos/6169177/pexels-photo-6169177.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
                    'https://images.pexels.com/photos/188679/pexels-photo-188679.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260'
                ]
            }
        },  
    }
</script>

<style>
    .image_1 {
        background-image: url('../assets/images/14-ton-refrigerated-truck.jpg');
    }
    .image_2 {
        background-image: url('../assets/images/8-ton-crane-hazchem-truck.jpg');
    }
    .image_3 {
        background-image: url('../assets/images/4-ton-drop-side-truck+-labour-canopy.jpg');
    }
</style>