<template>
    <form-template>
        <form >
            <div style="grid-template-columns: 2fr 1fr" class="xl:grid gap-6">
                <div>
                    <h1 class="font-bold text-xl text-gray-600 mt-6">{{ $route.path === '/contact' ? 'Contactformulier' : 'Request a Quote'}}</h1>
                    <p v-if="$route.path === '/contact'" class="text-gray-500 text-sm mt-4">Voor vragen of informatie over onze diensten <router-link to="/online-quote" class="text-red-500 underline hover:text-red-600">Online offerte aanvragen</router-link>. </p>
                    <hr class="mt-6">
                    <div class="w-full pt-4">
                        <div class="mt-4">
                            <div class="items-center md:grid grid-cols-2 gap-6">
                                <div v-for="(x, i) in form" :key="i" class="w-full">
                                    <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">{{x.title}}</label>
                                    <input :required="false" v-model="x.varible" class="w-full block px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" :type="x.type">
                                </div>
                            </div>

                            <div class="w-full mt-4">
                                <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">Bericht</label>
                                <textarea placeholder="Informatie ..." class="block w-full h-40 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-2">
                    <iframe v-if="$route.path === '/contact'" class="w-full h-full py-4 mt-6 rounded-lg" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Koolhovenstraat%209-11,schiedam+(Moparexpressnv)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"  style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
            <div class="flex items-center mt-6">
                <button class="px-8 py-2 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Verzenden</button>
            </div>
        </form>
    </form-template>
</template>

<script>
    import FormTemplate from '../templates/FormTemplate.vue'
    import WrapperTemplate from '../templates/WrapperTemplate.vue'
    export default {
        name: 'Contact',
        components: { 
            FormTemplate,
            WrapperTemplate 
        },
        data() {
            return {
                form: [
                    {
                        title: 'Uw naam', variable: '', type: 'text'
                    },
                    {
                        title: 'Telefoonnummer', variable: '', type: 'text'
                    },
                    {
                        title: 'Email Adres', variable: '', type: 'email'
                    }
                ]
            }
        },
        methods: {
            fetchSingleContact: () => {
                const singleContact = this.$store.state.helpers.siteInfo[0];
                return [singleContact.email, singleContact.phone]
            }
        }
    }
</script>