<template>
    <form-template>
        <div>
            <h1 class="font-bold text-xl text-gray-600 mt-6">Request a Quote</h1>
            <hr class="mt-6">
            <div style="grid-template-columns: 2fr 1fr" class="xl:grid gap-6">
                <form class="w-full">
                    <div class="mt-8">
                        <div class="items-center md:grid grid-cols-2 gap-6">
                            <div v-for="(x, i) in form" :key="i" class="w-full">
                                <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">{{x.title}}</label>
                                <input :required="false" v-model="x.variable" class="w-full block px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" :type="x.type">
                            </div>
                        </div>
                        <hr class="mt-10">
                        <div class="items-center md:grid mt-6 grid-cols-2 gap-6">
                            <div v-for="(x, i) in selection" :key="i" class="w-full">
                                <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">{{x.title}}</label>
                                <input :required="false" :value="x.variable" v-model="x.variable"  class="w-full block px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" :type="x.type">
                            </div>
                        </div>
                        <hr class="mt-10">
                        <div class="w-full mt-6">
                            <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">Extra informatie</label>
                            <textarea placeholder="Informatie zoals land van ophalen en producten..." class="block w-full h-40 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"></textarea>
                        </div>

                        <div class="flex  mt-6">
                            <button class="px-8 py-2 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Aanvragen</button>
                        </div>
                    </div>
                </form>
                <div class="space-y-4 py-4 mt-6">
                    
                    <h1 class="font-semibold text-gray-600 text-lg">Offerte informatie</h1>
                    <p class="text-gray-500 text-sm">Bij het aanvragen van een offerte voor import of export van goederen, zijn er een aantal belangrijke aspecten om te specificeren. Ten eerste is het belangrijk om de ophaallocatie van de goederen te specificeren. Dit kan bijvoorbeeld een magazijn, fabriek of andere locatie zijn. Daarnaast is de bestemmingslocatie van de goederen van belang, zoals een haven, luchthaven of magazijn.</p>

 <p class="text-gray-500 text-sm">Het type goederen dat je wilt importeren of exporteren is ook een belangrijk onderdeel van de offerte. Dit kan variëren van auto-onderdelen tot machines en elektronica. De hoeveelheid van de goederen is ook van belang bij het opstellen van de offerte, aangezien hoe meer goederen er zijn, hoe hoger de prijs zal zijn.</p>

 <p class="text-gray-500 text-sm">Het is ook essentieel om de verpakkings- en transportvereisten te specificeren, zoals het soort verpakking dat nodig is, zoals kratten, dozen of containers, en het type transportmiddel, zoals zeevracht of luchtvracht. Daarnaast kunnen de kosten voor de verzekering van de goederen tijdens transport worden opgenomen in de offerte. Het is belangrijk om duidelijkheid te hebben over wie verantwoordelijk is voor de verzekering van de goederen tijdens transport.</p>
                   
                </div>
            </div>
        </div>
    </form-template>
</template>

<script>
    import FormTemplate from '../templates/FormTemplate.vue'
    import WrapperTemplate from '../templates/WrapperTemplate.vue'
    export default {
        name: 'OnlineQuote',
        components: { 
            FormTemplate,
            WrapperTemplate 
        },
        data() {
            return {
                form: [
                    {
                        title: 'Jouw naam', variable: '', type: 'text'
                    },
                    {
                        title: 'Telefoonnummer', variable: '', type: 'text'
                    },
                    {
                        title: 'Email Adres', variable: '', type: 'email'
                    },
                    {
                        title: 'Provincie', variable: '', type: 'text'
                    },
                    {
                        title: 'Stad', variable: '', type: 'text'
                    },
                    {
                        title: 'Postcode', variable: '', type: 'text'
                    },
                ],
                selection: [
                    {
                        title: 'Ophaaldatum', variable: '2021-08-31' , type: 'date'
                    },
                    {
                        title: 'Ophaaldatum Tijd', variable: '16:00:00', type: 'time'
                    },
                    {
                        title: 'Leverings Datum', variable: '2021-09-01' , type: 'date'
                    },
                    {
                        title: 'Leverings Tijd', variable: '16:00:00', type: 'time'
                    },
                ]
            }
        },
        methods: {
            fetchSingleContact: () => {
                const singleContact = this.$store.state.helpers.siteInfo[0];
                return [singleContact.email, singleContact.phone]
            }
        }
    }
</script>